import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import VueSplide from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'

createApp(App)
  .use(store)
  .use(VueSplide)
  .use(router)
  .use(ToastPlugin, {
    position: 'top-right',
    pauseOnHover: true
  })
  .mount('#app')
