<template>
  <div class="contains">
    <p>Copyright © Aplicativo VN3 2024 - Todos os Direitos Reservados</p>
    <p>
      Desenvolvido por<a href="https://hoomweb.com/">
        <img src="../../assets/images/hoomweb.webp" alt="logo-hoomweb" />
      </a>
    </p>
  </div>
</template>
<script>
export default {
  name: 'copyrightVue'
}
</script>
<style scoped>
.contains {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #0f3f4f;
  padding: 1vh;
  width: 100%;
}

.contains p {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.contains img {
  padding-left: 0.5rem;
}

@media (min-width: 1440px) {
  h3 {
    font-size: 54px !important;
  }

  h4 {
    font-size: 43.2px !important;
  }

  h3 {
    font-size: 43.2px !important;
  }

  p {
    font-size: 18px !important;
  }
}

@media (max-width: 768px) {
  .contains p {
    font-size: 1.7vw;
  }
}
</style>
