<template>
  <div class="home">
    <NavBarVue />
    <router-view />
    <FooterVue />
    <CopyrightVue />
  </div>
</template>

<script>
import NavBarVue from './components/common/NavBarVue.vue'
import FooterVue from './components/common/FooterVue.vue'
import CopyrightVue from './components/common/CopyrightVue.vue'
export default {
  components: {
    NavBarVue,
    FooterVue,
    CopyrightVue
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  background-image: url('./assets/images/bannercidade.webp');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

@font-face {
  font-family: 'Francois One';
  font-display: swap;
  src: url('/src/assets/fonts/FrancoisOne.woff2') format('woff2'),
    url('/src/assets/fonts/FrancoisOne.woff') format('woff');
}

@font-face {
  font-family: 'Libre Franklin';
  font-display: swap;
  src: url('./assets/fonts/LibreFranklin.woff2') format('woff2')
    url('./assets/fonts/LibreFranklin.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-display: swap;
  src: url('/src/assets/fonts/Lato.woff2') format('woff2'),
    url('/src/assets/fonts/Lato.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('/src/assets/fonts/Nunito.woff2') format('woff2'),
    url('/src/assets/fonts/Nunito.woff') format('woff');
}
</style>
