import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/services',
    name: 'ServicesView',
    /**
     * Import async, importa somente quando for usar e não no ato do carregamento!
     */
    component: () => import('../views/ServicesView.vue')
  },
  {
    path: '/whitelabel',
    name: 'WhiteLabelView',
    component: () => import('../views/WhiteLabelView.vue')
  },
  {
    path: '/lgpd',
    name: 'LgpdView',
    component: () => import('../views/LgpdView.vue')
  },
  {
    path: '/about',
    name: 'WhoAreWeView',
    component: () => import('../views/WhoAreWeView.vue')
  },
  {
    path: '/contact',
    name: 'ContactUsView',
    component: () => import('../views/ContactUsView.vue')
  },
  {
    path: '/questions',
    name: 'QuestionsView',
    component: () => import('../views/QuestionsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  }
})

export default router
