<template>
  <div class="contain">
    <div class="d-flex justify-content-between align-items-end contact">
      <div class="form-box mb-4">
        <h1>Fale com Consultor</h1>
        <p>Envie uma mensagem que entraremos em contato assim que possível</p>
        <label for="name">Nome:
          <input type="text" v-model="name" id="name" required />
        </label>
        <label for="email">Email:
          <input type="email" v-model="email" id="email" required />
        </label>
        <label for="phone">Telefone:
          <input type="tel" v-model="phone" id="phone" required />
        </label>
        <label for="message">Mensagem:
          <textarea v-model="message" id="message" required></textarea>
        </label>
        <label for="type">
          Quero ser:
          <div style="
              display: flex;
              justify-content: space-around;
              margin: 3vh 0 2vh 0;
            ">
            <div style="display: inline-block">
              Condomínio Parceiro
              <input type="radio" value="Condomínio Parceiro" name="type" v-model="type" />
            </div>
            <div style="display: inline-block">
              Integrador Parceiro
              <input type="radio" value="Integrador Parceiro" name="type" v-model="type" />
            </div>
          </div>
        </label>
        <div id="turnstileContainer"></div>
        <button @click="enviarEmail" :disabled="loading || !challengeToken" :style="{
          color: challengeToken ? null : 'grey',
          backgroundColor: challengeToken ? null : 'grey'
        }">
          <p style="margin: 0" v-show="!loading">Enviar mensagem</p>
          <img class="img" v-show="loading" src="/img/loading.gif" alt="" />
        </button>
      </div>
      <div class="contact-info d-flex flex-column gap-4">
        <img class="contact-info-image-logo" src="../../assets/images/logo.webp" alt="logo" />
        <div class="d-flex gap-big">
          <div class="d-flex flex-column text-start">
            <h2>Fale com a gente</h2>
            <p class="d-flex flex-row align-items-end">
              <i class="fa-regular fa-envelope"> </i>
              contato@vn3.com.br
            </p>
            <a class="d-flex flex-row align-items-end" href="https://wa.me/5511988677841" target="_blank"
              style="text-decoration: none">
              <i class="fa-solid fa-phone"> </i>
              +55 (11) 98867-7841
            </a>
            <div class="d-flex gap-3 my-2">
              <a target="blank__" aria-label="Facebook" href="https://www.facebook.com/vn3anjo">
                <i class="fa-brands fa-facebook fa-xl" style="color: #ffffff"></i></a>
              <a target="blank__" aria-label="Instagram" href="https://www.instagram.com/vn3anjo/"><i
                  class="fa-brands fa-instagram fa-xl" style="color: #ffffff"></i></a>
              <a target="blank__" aria-label="Whatsapp" href="https://wa.me/5511988677841"><i
                  class="fa-brands fa-whatsapp fa-xl" style="color: #ffffff"></i></a>
            </div>
          </div>
          <div class="d-flex flex-column text-center">
            <h2>Institucional</h2>
            <div class="d-flex flex-row text-start justify-column-start">
              <div>
                <p>
                  <router-link to="/">Inicio</router-link>
                </p>
                <p>
                  <router-link to="/lgpd">LGPD</router-link>
                </p>
                <p>
                  <router-link to="/services">Serviços</router-link>
                </p>
                <p>
                  <router-link to="/whitelabel">Whitelabel</router-link>
                </p>
                <p>
                  <router-link to="/about">Quem Somos</router-link>
                </p>

                <p>
                  <a href="https://apiv2.vn3.com.br/privacy-policy" target="_blank">Termos de Uso e Política de
                    Privacidade</a>
                </p>

                <p>
                  <router-link to="/contact">Contato</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'FooterVue',
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      type: '',
      loading: false,
      challengeToken: null
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (window.turnstile) {
        clearInterval(interval)
        window.turnstile.render('#turnstileContainer', {
          sitekey: '0x4AAAAAAAXittDLoLt_yWmh',
          callback: this.challengeCallback
        })
      }
    }, 500)
  },
  methods: {
    challengeCallback(token) {
      this.challengeToken = token
    },

    async enviarEmail() {
      if (
        this.name === '' ||
        this.email === '' ||
        this.phone === '' ||
        this.message === '' ||
        this.type === ''
      ) {
        this.$toast.error('Por favor, complete todos os campos')
        return
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!this.email.match(emailRegex)) {
        this.$toast.error('Por favor, insira um email válido.')
        return
      }

      const phoneRegex = /^[\d -]{10,14}$/
      if (!this.phone.match(phoneRegex)) {
        this.$toast.error(
          'Por favor, insira um número de telefone válido: DDD + 9 Dígitos.'
        )
        return
      }

      this.loading = true
      this.$toast.info('Enviando email, aguarde um momento...')

      try {
        const response = await axios.post(
          'https://apiv2.vn3.com.br/api/sendmail',
          {
            name: this.name,
            email: this.email,
            phone: this.phone,
            messagetxt: this.message,
            type: this.type,
            'cf-turnstile-response': this.challengeToken
          }
        )

        if (response.data.success) {
          this.$toast.success('Email enviado com sucesso.')
          this.name = ''
          this.email = ''
          this.phone = ''
          this.message = ''
          this.type = ''
          this.challengeToken = null
        } else {
          this.$toast.error('Ocorreu um erro, tente novamente mais tarde.')
        }
      } catch (error) {
        console.error('Erro ao enviar email:', error)
        this.$toast.error('Ocorreu um erro durante o envio do email.')
      } finally {
        window?.turnstile?.reset('#turnstileContainer')
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.contain {
  display: flex;
  justify-content: center;
  padding: 10% 0 0 0;
  width: 100%;
  background-image: url('../../assets/images/footer2.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.contact {
  width: 82%;
  max-width: 1440px;
}

.img {
  max-height: 28px;
}

.form-box {
  padding: 3%;
  gap: 1vw;
  justify-content: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.form-box h1 {
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 400;
  font-size: 3.75vw;
  text-align: start;

  color: #0f3f4f;
}

.form-box p,
.form-box a {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vw;
  text-align: start;

  color: #0f3f4f;
}

.form-box label {
  text-align: start;
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.35vw;

  color: #000000;
}

.form-box input {
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.5%;
  gap: 10px;
  width: 100%;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}

.form-box textarea {
  width: 100%;
  color: rgb(37, 37, 37);
  padding: 1%;
}

.form-box button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2%;
  width: 100%;
  background: #d9ef3d;
  font-size: 1.25vw;
  border: 1px solid #c0d240;
  border-radius: 10px;
}

.form-box button:hover {
  opacity: 0.8;
}

.contact-info {
  width: 40%;
  padding: 5% 0;
}

.contact-info h2 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;

  color: #ffffff;
}

.contact-info p,
.contact-info a {
  /* P Desktop */

  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;

  margin: 4px;

  color: #ffffff;
}

.gap-big {
  gap: 15%;
}

.fa-envelope {
  margin-right: 5%;
}

.fa-facebook {
  margin-top: 15%;
}

.fa-instagram {
  margin-top: 15%;
}

@media (min-width: 1440px) {
  h1 {
    font-size: 54px !important;
  }

  h2 {
    font-size: 28px !important;
  }

  h1 {
    font-size: 43.2px !important;
  }

  p,
  a {
    font-size: 18px !important;
  }

  label {
    font-size: 18px !important;
  }
}

@media (max-width: 768px) {
  .img {
    max-height: 16px;
  }
}

@media (max-width: 540px) {
  .contact {
    flex-direction: column;
    align-items: center !important;
  }

  .form-box {
    width: 100%;
  }

  .img {
    max-height: 18px;
  }

  .form-box p,
  .form-box a {
    font-size: 11px;
  }

  .form-box label {
    font-size: 12px;
  }

  .form-box button {
    font-size: 3vw;
  }

  .contact-info {
    width: 75%;
    align-items: center;
  }

  .contact-info p,
  .contact-info a {
    font-size: 12px;
  }

  .contact-info img {
    max-width: 70%;
  }

  .contact-info h2 {
    font-size: 13px;
  }

  .gap-big {
    gap: 9vw;
  }
}
</style>
